@import "../checkoutVariables.scss";


.categoryTitle {
	text-transform: uppercase;
	letter-spacing: 0.0625rem;
	line-height: 1.5 !important;
	display: flex;
	justify-content: center;
}

.categoryTitleSingle {
	text-transform: uppercase;
	line-height: 1.5 !important;
	display: flex;
	justify-content: center;
	font-size: 7.5rem;
}

.menuItemList {
	height: calc(#{(100 - $headerHeight - $bottomBarHeight) + 0vh} - #{$bottomBarMarginHeight});
	margin: 0 auto;
	overflow-y: scroll;
	padding: 0 1rem;
	position: relative;

	.divider {
		margin: 0 2rem 2rem;
	}

	.shortFiller {
		margin: 2rem;
	}

	.subCategory {
		margin: 0 0 2rem;
	}
}