@import "./checkoutVariables.scss";

.MuiDialog-container.MuiDialog-scrollPaper {
	min-height: 100vh;
}

.menuContainer>.main {
	height: calc(#{(100 - $headerHeight - $bottomBarHeight) + 0vh} - #{$bottomBarMarginHeight});
}

.menuContainer,
.dialogContainer {
	display: block;
	padding: 0 $headerPadding !important;

	.header {
		position: relative;
		height: $headerHeight + 0vh;
		overflow-y: hidden;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		.language-flag-strip {
			position: absolute;
			top: $headerPadding;
			left: 0;
		}
	}

	.customLogo {
		height: calc(#{$headerHeight}vh - #{$headerPadding * 2});
	}

	.main {
		position: relative;

		&>div {
			position: relative;
		}
	}

	&.dialogContainer {
		.header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding: $headerPadding;
			z-index: 1;
		}

		.main {
			margin-top: $headerHeight + 0vh;
		}
	}
}