@use "sass:math";

.counterGroup {
	$smallSquare: 2.5rem;
	$mediumSquare: 3.5rem;
	$largeSquare: 4.5rem;

	button {
		width: $mediumSquare;
		min-width: $mediumSquare !important;
		height: $mediumSquare;
		border-radius: 0.25rem;
		margin: 0;
		padding: 0;

		span {
			margin: 0;
			padding: 0;

			svg {
				font-size: (math.div($mediumSquare, 5) * 3) !important;
			}
		}

		&.counter {
			font-size: (math.div($mediumSquare, 5) * 2) !important;
		}
	}

	&.large {
		button {
			width: $largeSquare;
			min-width: $largeSquare !important;
			height: $largeSquare;

			span {
				svg {
					font-size: (math.div($largeSquare, 5) * 3) !important;
				}
			}

			&.counter {
				width: $largeSquare * 2;
				border-width: 0.125rem 0;
				border-style: solid;
				font-size: (math.div($largeSquare, 5) * 2) !important;
			}
		}
	}
}

.counterGroupSimple {
	$mediumSquare: 3rem;
	width: ($mediumSquare * 2.5) + 0.5;

	button {
		width: ($mediumSquare * 1.25);
		min-width: $mediumSquare !important;
		height: $mediumSquare;
		border-radius: 0.625rem;
		margin: 0;
		padding: 0;

		span {
			margin: 0;
			padding: 0;

			svg {
				font-size: math.div($mediumSquare, 2) !important;
			}
		}
	}
}
