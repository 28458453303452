html {
	// the RootEM is calculated as current viewport width ratio
	// when width is 1080px (FULL HD: 1080x1920px) we want 16px as Root Element Measure (REM)
	$viewportWidthFullHD: 1080;
	$emFullHD: 16;

	font-size: #{calc($emFullHD * 100 / $viewportWidthFullHD)}vw;

	// at 1200px stop responsiveness; REM fixed
	@media (min-width: 1200px) {
		$viewportWidthMaxSupported: 1200;

		font-size: #{calc($viewportWidthMaxSupported * $emFullHD / $viewportWidthFullHD)}px;
	}
}

body,
.Main {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.Main-logo {
	height: 40vmin;
	pointer-events: none;
}

.Main-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
