@use "sass:math";

$totalHeight: 120rem;
$totalWidth: 67.5rem;

$headerHeight: 10rem;
$tabMenuWidth: 12.5rem;
$panelWidth: $totalWidth - $tabMenuWidth;
$panelRatio169Height: math.div($panelWidth, 9) * 16;
$verticalTabMenuPadding: 3rem;

.configContainer {
	margin: 0;
	padding: 0 !important;
	height: 100vh;
	overflow-y: scroll;

	.header {
		margin: 0;
		border-bottom: 0.25rem solid;
		padding: 0 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $headerHeight;

		.logo {
			width: 10rem;

			img {
				margin: 0 0 -0.7rem -0.2rem;
			}
		}

		.iqSelf {
			width: 16.5rem;

			img {
				margin: 0 2rem -1.35rem 2rem;
			}
		}
	}

	.main {
		display: flex;
		padding: 0;

		.version {
			position: absolute;
			bottom: 0;
			left: 0;
			height: $verticalTabMenuPadding * 2;
			width: $tabMenuWidth;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			z-index: 1;

			& > p {
				font-size: 1rem;
				margin: 0;
			}
		}

		.tabMenu {
			width: $tabMenuWidth;
			background-color: rgb(238, 238, 238);
			z-index: 1;

			.MuiTabs-flexContainer {
				padding-top: $verticalTabMenuPadding;
				padding-bottom: $verticalTabMenuPadding;
				min-height: $totalHeight - $headerHeight - ($verticalTabMenuPadding * 2);
			}

			.MuiTabs-indicator {
				width: 0.3rem;
			}

			button {
				text-transform: uppercase;
				font-weight: bold;
				justify-content: flex-start;
				padding: 0.6rem;
				font-size: 1.05rem;
				min-height: 4.5rem;
				white-space: nowrap;

				svg {
					font-size: 1.7rem;
				}
			}
		}

		.panels {
			width: $panelWidth;
			height: $totalHeight - $headerHeight;
			overflow-x: hidden;
			overflow-y: scroll;

			.vertical-tabPanel {
				min-height: 100%;
				width: 100%;
			}

			.vertical-tabPanel-preview {
				padding: 0;

				.panelHeader {
					height: $totalHeight - $headerHeight - $panelRatio169Height;
					padding: 1.5rem;
				}

				.previewFrame {
					overflow: scroll;
					height: $panelRatio169Height;

					& > div {
						width: 67.5rem;
						height: 120rem;
					}

					.verticalFader {
						display: none;
					}
				}
			}

			.vertical-tabPanel-upSelling {
				padding: 0;

				.panelHeader {
					height: $totalHeight - $headerHeight - $panelRatio169Height;
					padding: 1.5rem;
				}

				.reorderFrame {
					& > div {
						overflow-y: scroll;
						height: $panelRatio169Height - $headerHeight;

						.isDragging {
							background-color: #eee;
						}
					}
				}
			}

			.vertical-tabPanel-access {
				.appRow {
					padding: 1rem;
				}

				.iqSelfLogo {
					width: 5rem;
					padding: 0.5rem;
					margin-right: 1rem;
				}

				.iqSelfFavicon {
					width: 5rem;
					height: 5rem;
					padding: 0.5rem;
					margin-right: 1rem;
					border-radius: 1rem;
					border: 0.0625rem solid #33333333;
				}

				.appAction {
					text-transform: uppercase;
					font-size: 1rem;

					svg {
						font-size: 1.5rem;
					}
				}
			}
		}

		h5,
		h4 {
			text-transform: uppercase;
			margin: 1rem 0;
		}

		.bgImages {
			max-width: 12.5rem;
			max-height: 22rem;

			img {
				border: 0.125rem solid #333;
				width: 12.5rem;
			}
		}

		.logoImage {
			width: 12.5rem;
		}
	}

	.category {
		width: auto !important;
	}

	.icon {
		border-radius: 50%;
		width: 7.25rem;
		height: 7.25rem;
		min-width: 3.5rem;

		.icon-image {
			background-repeat: no-repeat;
			background-size: cover;
			width: 90%;
			height: 90%;
			margin: 5%;
		}
	}

	.toggleButtonGroup {
		width: 100%;

		& > button {
			// width: 50%;
			flex: 1;
			text-transform: uppercase;
		}
	}
}
