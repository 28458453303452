.ingredientRow {
	.col-button {
		width: 25% !important;
	}

	.col-desc {
		width: 60% !important;
	}

	.col-price {
		width: 15% !important;
	}
}
