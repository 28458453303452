.orderReview {
	display: flex;
	flex-direction: column;
	align-items: center;

	.titles {
		position: fixed !important;
		height: 8vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
		width: 100%;
	}

	h3 {
		margin: 2rem 0 0;
		font-size: 3.2rem;
	}

	.marketingHint {
		width: 100%;
		position: initial;
	}

	.itemTitle {
		font-size: 1.5rem;
	}

	.quantity {
		display: flex;

		button {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 0.25rem;
			margin: 0;
			padding: 0;

			span {
				margin: 0;
				padding: 0;

				svg {
					font-size: 2.25rem;
				}
			}
		}

		& > p {
			width: 3.5rem;
			height: 3.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	ul.variations {
		margin: 1rem 0;
		list-style: none;
		padding: 0;
	}

	.customize {
		font-size: 0.8rem;
		padding: 1.25rem 1rem;
		border-radius: 3rem;
		line-height: 1;
	}
}
