.MuiFormGroup-root {
	flex-direction: row !important;
}

.radioOption {
	margin: 1rem 0.5rem;
	border-radius: 1rem;
	border-width: 0.25rem;
	border-style: solid;
	position: relative;

	& > label {
		margin: 0;
		position: relative;
		width: 100%;
		padding: 1.5rem;

		.MuiRadio-root {
			padding: 0;
			display: none;

			& + * {
				display: block;
				width: 100%;
				line-height: 1.25;
				text-align: center;
			}
		}
	}
}
