.language-flag {
	border-radius: 50%;

	border: 0.3125rem solid rgba(255, 255, 255, 0.4);

	width: 3.75rem;
	height: 3.75rem;

	&.selected {
		border: 0.3125rem solid rgba(0, 0, 0, 0.8);
	}

	&.small {
		width: 2.75rem;
		height: 2.75rem;
		border: 0 none;
	}
}
