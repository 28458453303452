@import "../checkoutVariables.scss";

.itemCard {
	//border-radius: 0 !important;
	position: relative;
	border-width: 0.125rem !important;
	height: 100%;
	display: flex;
	flex-direction: column;

	[class$="MuiCardContent-root"]:last-child {
		padding-bottom: 0.5rem;
	}

	.add-cart-btn {
		border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;
		padding-right: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5rem;
		min-width: 8.5rem;
		justify-content: space-between;

		span[class$="endIcon"] svg {
			font-size: 1.5rem;
		}
	}

	.card-footer {
		display: flex;
		justify-content: space-between;
	}

	.itemPrice {
		font-size: 1.25rem;
		line-height: 1.7;
	}

	.itemClickable {
		position: inherit;
		width: 100%;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
	}

	.itemTitle {
		align-items: flex-start;
	}

	.itemCounter {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		z-index: 1;
		display: block;
		border-style: solid;
		border-right-color: transparent;
		border-bottom-color: transparent;

		& > div {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
		}
	}
}

.itemRow {
	& > td {
		border: 0 none;
	}

	.col-button {
		width: 25% !important;
	}

	.col-desc {
		width: 60% !important;
	}

	.col-price {
		width: 15% !important;
	}
}
