$headerHeight: 7;
$bottomBarHeight: 8;
$headerPadding: 1.5rem;
$bottomBarMarginHeight: 0.625rem;

.hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 0.0625rem;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 0.0625rem;
}

.verticalFiller {
	height: $bottomBarHeight + $headerHeight + 0vh;
	width: 100%;
}

.verticalFader,
.verticalFaderStatic {
	width: 100%;
	position: fixed !important;
	height: calc(#{$bottomBarHeight + 0vh} + #{$bottomBarMarginHeight});
	bottom: calc(#{$bottomBarHeight + 0vh} + #{$bottomBarMarginHeight});
	left: 0;
	pointer-events: none;
}

.underline {
	text-decoration: underline;
}