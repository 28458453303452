.language-flag-strip {
	.language-flag {
		position: relative;
		margin-right: 2rem;
		transition: all 0.5s linear;
	}

	&.condensed {
		.language-flag {
			position: absolute;
			margin-right: 0;
			z-index: 1;
			left: 0;

			&.selected {
				z-index: 2;
			}
		}
	}
}
