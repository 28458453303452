.cartRow {
	border-style: solid;
	border-width: 0.0625rem;

	.col-button {
		width: 20% !important;
	}

	.col-desc {
		width: 67% !important;
	}

	.col-price {
		width: 13% !important;
	}
}
