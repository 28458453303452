.dialogContainer.editableItemDialog {
	min-height: auto !important;
	padding: 0 !important;
}

.itemContainer {
	$footerHeight: 8.625vh;
	$headerHeight: 54rem;
	$headerHeightSetMenu: 38rem;

	position: relative;
	padding-top: $headerHeight;
	height: 100vh;
	overflow: hidden;

	&.setMenu {
		padding-top: $headerHeightSetMenu;

		.titles {
			height: $headerHeightSetMenu;
		}

		.scrollable {
			height: calc((100vh - $footerHeight) - $headerHeightSetMenu);
		}
	}

	.titles {
		position: absolute !important;
		height: $headerHeight;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
		width: 100%;
		margin: 0;
		top: 0;
		left: 0;
		right: 0;

		.itemImg {
			height: 23rem;
			width: calc(100% - 3rem);
			overflow: hidden;
			margin: 3rem 2rem 0 1rem;
			display: flex;
			justify-content: flex-end;

			img {
				object-fit: contain;
				width: 100%;
				height: 23rem;
			}
		}

		.itemName {
			display: flex;
			flex-direction: column;
			height: 26rem;
			justify-content: space-between;
			padding: 0;

			.customLogo {
				padding-right: 1rem;
			}

			.boxNamePrice {
				display: flex;
				flex: 1 1 2rem;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: space-around;
			}
		}

		.itemDescription {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 2rem 1rem 1rem;

			h5 {
				margin: 0 1rem;
				text-align: justify;
			}
		}
	}

	.scrollable {
		width: 100%;
		height: calc((100vh - $footerHeight) - $headerHeight);
		overflow-y: auto;

		&>div {
			padding: 0.5rem 2rem;
		}
	}
}

.disclaimer {

	h1,
	h2 {
		text-align: center;
	}
}