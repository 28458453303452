@keyframes shakeY {
	from,
	to {
		transform: translate3d(0, 0, 0) rotate(90deg);
	}

	50% {
		transform: translate3d(0, -0.5rem, 0) rotate(90deg);
	}
}

.confirm-modal-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: 100% 100%;

	.confirm-modal-box {
		height: 60%;
		width: 70%;
		position: relative;
		top: 18%;
		left: 15%;
		border-radius: 2rem;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: hidden;

		.imageContainer {
			height: 24rem;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			padding: 3rem 0;

			.customLogo {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.children-box {
			height: 100%;
			width: 100%;
			padding: 3rem;

			& > div {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-content: center;
				align-items: center;
				justify-content: space-around;
			}
		}
	}

	.confirm-modal-button-box {
		width: 70%;
		position: relative;
		top: 22%;
		left: 15%;
		padding: 1rem;

		.MuiButton-root {
			border-radius: 3rem;
			padding: 0.5rem 1.5rem;

			& > span {
				font-size: 2rem;
			}

			.MuiSvgIcon-root {
				font-size: 3.5rem !important;
			}
		}
	}

	.confirm-modal-outside {
		position: absolute;
		bottom: 1rem;
		right: 1rem;

		& .embeddedReaderPointer {
			&.animated {
				animation: shakeY 1s ease-in-out 1s infinite;
			}
		}
	}
}
