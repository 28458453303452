.configContainer {
	.main {
		.presets {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.preset {
			margin: 0.5rem;
			position: relative;

			.content {
				padding: 1.25rem !important;

				h4 {
					font-size: 1.8rem;
					margin: 0 0 1rem;
					text-transform: none;
				}

				.fonts {
					margin: 2rem 0 0;
					display: flex;
					flex-direction: column;
				}
			}

			&.large {
				display: flex;
				justify-content: center;
				overflow: hidden;
				width: calc(100% - 1rem);

				.squares {
					justify-content: center;

					& > div {
						margin: 0 1rem;

						.square {
							width: 9rem;
							height: 6rem;
						}
					}
				}
			}

			.activeTriangle {
				position: absolute;
				top: 0;
				right: 0;
				width: 0;
				height: 0;
				z-index: 1;
				display: block;
				border-style: solid;
				border-width: 2.5rem;
				border-left-color: transparent;
				border-bottom-color: transparent;

				& > div {
					top: -2rem;
					position: relative;
				}
			}

			.MuiCardActions-root {
				justify-content: flex-end;
			}
		}

		.squares {
			display: flex;
			margin: 0 0 1rem;
			justify-content: flex-start;
			text-align: start;
			font-size: 1rem;
			position: relative;

			& > span {
				margin: 0 0.5rem;
			}

			.square {
				width: 6rem;
				height: 5rem;
				display: block;
				border-radius: 1rem;
				border: 0.25rem solid white;
				box-shadow:
					0 0 0 0.125rem rgba(0, 0, 0, 0.1),
					inset 0 0 0 0.125rem rgba(0, 0, 0, 0.1);
				position: relative;

				& > div {
					position: absolute;
					right: 0;
					top: 0.5rem;
					display: block;
					width: 100%;
					text-align: center;
				}

				p {
					display: flex;
					width: 100%;
					height: 100%;
					align-items: flex-end;
					justify-content: right;
					font-size: 1rem;
					padding: 0.25rem;
				}
			}

			.popoverWrapper {
				position: fixed;
				height: 100vh;
				width: 100vw;
				top: 0;
				left: 0;
				z-index: 10000;
				background-color: rgba(0, 0, 0, 0.9);
				display: flex;
				justify-content: center;
				align-items: center;

				.popover {
					border-radius: 1rem;
					box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
					z-index: 10001;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.react-colorful {
						width: 80vw;
						height: 25vh;

						.react-colorful__hue {
							height: 3vh !important;
						}
					}

					.hexInput {
						display: block;
						box-sizing: border-box;
						font-size: 3rem !important;
						margin: 1rem;
						padding: 0.5rem;
						border: 0.0625rem solid #ddd;
						border-radius: 0.25rem;
						background: white;
						outline: none;
						text-transform: uppercase;
						text-align: center;
					}
				}
			}
		}

		.selectFont {
			min-width: 28rem;
			margin: 1rem 3rem;
		}
	}
}
