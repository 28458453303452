@import "../checkoutVariables.scss";

.sidebar {
	position: relative;
	height: 100vh - $headerHeight;
	margin: 0 auto;
	overflow-y: scroll;
	text-transform: uppercase;

	&.vertical-centered {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	li>div {
		flex-direction: column;

		&>div {
			text-align: center;
		}
	}

	.icon {
		border-radius: 50%;
		width: 7.25rem;
		height: 7.25rem;
		min-width: 3.5rem;

		.icon-image {
			background-repeat: no-repeat;
			background-size: cover;
			width: 90%;
			height: 90%;
			margin: 5%;
		}
	}

	.groupText span {
		font-size: 1.25rem;
		line-height: 1.2;
		font-weight: bold;
		letter-spacing: -0.0625rem;
		padding: 0.25rem;
	}

	.childCategory>li {
		margin: 0;
		padding: 0.4rem 0;
		border-top: 0.125rem solid rgba(0, 0, 0, 0.3);

		&>div {
			margin: 0;
			padding: 0;
		}
	}
}