@import "../checkoutVariables.scss";

.bottom-bar-container {
	position: fixed;
	bottom: 0.625rem;
	left: 0.3125rem;
	right: 0.3125rem;
	width: auto !important;
	min-height: $bottomBarHeight + 0vh;
	border-radius: 1rem;
	z-index: 1;

	.bottom-bar-main-box {
		height: 8rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.bottom-bar-children-box {
			height: 100%;
			width: 100%;
			padding: 0 1rem;
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			justify-content: space-between;

			& > div {
				width: 32%;
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&:first-child {
					justify-content: flex-start;
				}

				&:last-child {
					justify-content: flex-end;
				}

				& > button {
					span {
						line-height: 1;
					}
				}
			}
		}

		.bottom-bar-powered-by {
			width: 100%;
			height: 3rem;
			padding: 0 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: 0;
		}

		.MuiButton-root {
			border-radius: 3rem;
			padding: 0.75rem 1.5rem;

			.MuiSvgIcon-root {
				font-size: 2.5rem !important;
			}

			&.cancel-order-btn {
				border-width: 0.125rem;
				border-style: solid;
			}
		}
	}
}

.bagBadge {
	margin-left: 1rem;
	width: 3.625rem;
	height: 3.625rem;
	display: block;

	.MuiBadge-badge {
		right: 49%;
		top: 63%;
		min-width: 1.25rem;
		height: 1.25rem;
		border-radius: 0.625rem;
		padding: 0 0.375rem;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}
